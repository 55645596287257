import { FC, useMemo } from "react";
import Layout from "@vms/Layout";
import { Navigate, useRoutes } from "react-router-dom";
import Routes from "./Routes";
import BuildPage from "@pages/BuildPage";
import LibraryPage from "@pages/LibraryPage";
import InspirePage from "@pages/InspirePage";

const Router: FC = () => {
    const children = useMemo(
        () => [
            { path: '*', element: <Navigate to="/"/> },
            { path: Routes.Build, element: <BuildPage/> },
            { path: Routes.Inspire, element: <InspirePage/> },
            { path: Routes.Library, element: <LibraryPage/> },
        ],
        []
    );

    const routing = useRoutes([{
        path: '/',
        element: <Layout/>,
        children,
    }]);
    return <>{routing}</>
};

export default Router;