import { Id } from "@models/index";
import { components } from "@api/apiSchema";

export interface iConceptItemIndex {
    unbrandedPi: number,
    unbrandedPiStar: number,
    uniqueness: number,
    uniquenessStar: number,
    frequency: number,
    frequencyStar: number,
    draw: number,
    drawStar: number,
    score: number,
    viability: string,
}

export interface iConceptItem {
    id: Id;
    chainName: string;
    date: string;
    name: string;
    imageUrl: string;
    description: string;
    kind: string;
    note: string;
    url: string;
    index?: iConceptItemIndex;
}

type ApiModel = components["schemas"]["ConceptIdeation.InspirePage.Models.LaunchedItem"];

interface iData {
    apiModel?: ApiModel;
}

export default class ConceptItem implements iConceptItem {
    static defaultData: iConceptItem = {
        id: 0,
        chainName: '',
        date: '',
        name: '',
        imageUrl: '',
        description: '',
        kind: '',
        note: '',
        url: '',
    };

    static defaultIndexData: iConceptItemIndex = {
        unbrandedPi: 0,
        unbrandedPiStar: 0,
        uniqueness: 0,
        uniquenessStar: 0,
        frequency: 0,
        frequencyStar: 0,
        draw: 0,
        drawStar: 0,
        score: 0,
        viability: '',
    };

    id = ConceptItem.defaultData.id;
    chainName = ConceptItem.defaultData.chainName;
    date = ConceptItem.defaultData.date;
    name = ConceptItem.defaultData.name;
    imageUrl = ConceptItem.defaultData.imageUrl;
    description = ConceptItem.defaultData.description;
    kind = ConceptItem.defaultData.kind;
    note = ConceptItem.defaultData.note;
    url = ConceptItem.defaultData.url;

    constructor(data?: iData) {
        if (data && data.apiModel) {
            this.fromApiModel(data.apiModel);
        }
    }

    setData(data: Partial<iConceptItem>) {
        Object.assign(this, data);
    }

    fromApiModel(apiModel: ApiModel) {
        const data: iConceptItem = {
            id: apiModel.id || ConceptItem.defaultData.id,
            chainName: apiModel.chainName || ConceptItem.defaultData.chainName,
            date: apiModel.date || ConceptItem.defaultData.date,
            name: apiModel.name || ConceptItem.defaultData.name,
            imageUrl: apiModel.imageUrl || ConceptItem.defaultData.imageUrl,
            description: apiModel.description || ConceptItem.defaultData.description,
            kind: apiModel.kind || ConceptItem.defaultData.kind,
            note: apiModel.note || ConceptItem.defaultData.note,
            url: apiModel.url || ConceptItem.defaultData.url,
        };

        if (apiModel.index) {
            data.index = {
                unbrandedPi: apiModel.index.unbrandedPi || ConceptItem.defaultIndexData.unbrandedPi,
                unbrandedPiStar: apiModel.index.unbrandedPiStar || ConceptItem.defaultIndexData.unbrandedPiStar,
                uniqueness: apiModel.index.uniqueness || ConceptItem.defaultIndexData.uniqueness,
                uniquenessStar: apiModel.index.uniquenessStar || ConceptItem.defaultIndexData.uniquenessStar,
                frequency: apiModel.index.frequency || ConceptItem.defaultIndexData.frequency,
                frequencyStar: apiModel.index.frequencyStar || ConceptItem.defaultIndexData.frequencyStar,
                draw: apiModel.index.draw || ConceptItem.defaultIndexData.draw,
                drawStar: apiModel.index.drawStar || ConceptItem.defaultIndexData.drawStar,
                score: apiModel.index.score || ConceptItem.defaultIndexData.score,
                viability: apiModel.index.viability || ConceptItem.defaultIndexData.viability,
            };
        }
        this.setData(data);
    }
}