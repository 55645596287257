import { FCX, Id, iOption } from "@models";
import cn from "classnames";
import React, { useMemo } from "react";
import Select from "@components/Select";

const AppealSelect: FCX<{
    value: Id;
    options: iOption[];
    setValue: (id: Id) => void;
}> = ({
    value,
    options,
    setValue,
}) => {
    const text = useMemo(
        () => {
            return options.find(i => value === i.id)?.name || '';
        },
        [options, value]
    );

    return (
        <div className="InspireResults__input-container">
            <Select
                options={options}
                value={value ? [value] : []}
                onChange={setValue}
                isDarkMode
                closeOnSelect
                testId="dropdownInspirePageResultsSelectAppeal"
            >
                <div
                    className={cn(
                        'InspireResults__input',
                        'is-dark-mode',
                        'is-appeal-select',
                    )}
                >
                    <div className="InspireResults__input-inner">
                        Appeals To: {text}
                    </div>
                </div>
            </Select>
        </div>
    );
};

export default AppealSelect;