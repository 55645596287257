import { useEffect, useState, useRef } from 'react';

const DEBOUNCE_MS = 500;
export default function useWindowSizeDebounced() {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [height, setHeight] = useState<number>(window.innerHeight);
    const timer = useRef<any>(null);


    useEffect(() => {
        function handleWindowResize() {
            clearTimeout(timer.current);

            timer.current = setTimeout(() => {
                setWidth(window.innerWidth);
                setHeight(window.innerHeight);
            }, DEBOUNCE_MS);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return { width, height };
}
