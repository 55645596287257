import { MouseEvent } from 'react';
import { FCX } from '@models';
import { Link, NavLink, To } from 'react-router-dom';
import cn from 'classnames';

export enum ButtonType {
    Button,
    Link,
    NavLink,
    Anchor,
}

export interface iProps {
    type?: ButtonType;
    onClick?: (event: MouseEvent) => void;
    link?: To;
    disabled?: boolean;
}

const Button: FCX<iProps> = ({
    type= ButtonType.Button,
    onClick,
    disabled,
    link,

    children,
    className,
    style,
    testId,

    ...attrs
}) => {

    const props = {
        className: cn(
            className,
            'Button',
            'interactive',
        ),
        onClick,
        style,
        children,
        disabled,
        'data-testid': testId
            || (attrs.id ? `button${attrs.id}` : 'button'),
        ...attrs,
    };

    switch (type) {
        case ButtonType.Button: {
            return <button {...props} disabled={disabled} />;
        }
        case ButtonType.Link: {
            return <Link to={link || '#'} {...props} />;
        }
        case ButtonType.NavLink: {
            return <NavLink to={link || '#'} {...props} />;
        }
        case ButtonType.Anchor: {
            return <a target="_blank" href={(link as string) || '#'} {...props} rel="noreferrer">{children}</a>;
        }
        default:
            return null;
    }
};

export default Button;