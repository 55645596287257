import { useEffect, useState } from "react";
import { iOption } from "@models";
import { paths } from './apiSchema';
import { useFetch } from './index'

type Response = paths['/InspirePage/GetCommonData']['get']['responses']['200']['content']['application/json'];

export interface iCommonData {
    chains: iOption[];
    segments: iOption[];
    generationRespondentTypes: iOption[];
}

const defaultValue: iCommonData = {
    chains: [],
    segments: [],
    generationRespondentTypes: [],
};

export default function useInspireCommonData() {
    const [data, setData] = useState<iCommonData>(defaultValue);

    const result = useFetch<Response>({
        url: '/InspirePage/GetCommonData',
    });

    useEffect(() => {
        if (!result.isLoading && !!result.data) {
            setData({
                chains: (result.data.chains || []).map(i => ({
                    id: i.id ?? 0,
                    name: i.name ?? '',
                    segmentId: i.segmentId ?? 0,
                })),

                segments: (result.data.segments || []).map(i => ({
                    id: i.id ?? 0,
                    name: i.name ?? '',
                })),

                generationRespondentTypes: (() => {
                    const items = result.data.respondentTypes
                        ?.find(i => i.id === 2)
                        ?.items?.filter(i => i.id !== 20) || [];

                    return items.map(i => ({
                        id: i.id ?? 0,
                        name: i.name ?? '',
                    }));
                })(),
            });
        } else {
            setData(defaultValue);
        }
    }, [result.isLoading, result.data]);

    return {
        isLoading: result.isLoading,
        data,
    };
}