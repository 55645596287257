import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import copy from 'copy-to-clipboard';
import cn from "classnames";
import { toast } from "react-toastify";

export enum DevModeEnum {
    None,
    TestId,
    Interactive,
}

const DEV_TOOLS_TITLE = {
    [DevModeEnum.None]: '',
    [DevModeEnum.TestId]: 'DevTools Test ID Mode',
    [DevModeEnum.Interactive]: 'DevTools Interactive Mode',
}

const DEV_TOOLS_COMMAND = {
    [DevModeEnum.None]: '',
    [DevModeEnum.TestId]: 'perform [shift + alt + click] to copy test ID',
    [DevModeEnum.Interactive]: '',
}

const DEV_TOOLS_NEXT_MODE = {
    [DevModeEnum.None]: DevModeEnum.TestId,
    [DevModeEnum.TestId]: DevModeEnum.Interactive,
    [DevModeEnum.Interactive]: DevModeEnum.None,
}

const DevTools: FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [devMode, setDevMode] = useState<DevModeEnum>(DevModeEnum.None);
    const [messages, setMessages] = useState<string[]>([]);

    const title = useMemo(
        (): ReactNode => DEV_TOOLS_TITLE[devMode] || '',
        [devMode]
    );

    const command = useMemo(
        (): ReactNode => DEV_TOOLS_COMMAND[devMode] || '',
        [devMode]
    );

    useEffect(() => {
        const clickListener = (event: MouseEvent) => {
            if (devMode === DevModeEnum.TestId) {
                const nearestNode = (event.target as Element)?.closest('[data-testid]');
                if (event.altKey && event.shiftKey && !!nearestNode) {
                    event.stopImmediatePropagation();
                    event.preventDefault();
                    copy(nearestNode.getAttribute('data-testid') || '');
                    toast.success('Test ID copied to clipboard');
                }
            }
        };
        const keyDownListener = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                if (event.altKey && event.shiftKey) {
                    setDevMode(DEV_TOOLS_NEXT_MODE[devMode]);
                    DEV_TOOLS_TITLE[DevModeEnum.None] = 'DevTools is OFF';
                }
            }
        };

        const mouseMoveListener = (event: MouseEvent) => {
            if (devMode === DevModeEnum.TestId) {
                event.stopImmediatePropagation();
                const nearestNode = (event.target as Element)?.closest('[data-testid]');
                const messages = [];

                if (nearestNode) {
                    const parentNode = (nearestNode.parentNode as Element)?.closest('[data-testid]');
                    if (parentNode) {
                        messages.push(`Parent Test ID: ${parentNode.getAttribute('data-testid')}`);
                    }
                    messages.push(`Test ID: ${nearestNode.getAttribute('data-testid')}`);
                    messages.push(`Node: ${nearestNode.nodeName.toLowerCase()}.${Array.from(nearestNode.classList).join('.')}`);
                }
                setMessages(messages);
            } else {
                setMessages([]);
            }
        }

        if (process.env.REACT_APP_DEV_TOOLS) {
            window.addEventListener('click', clickListener, true);
            window.addEventListener('keydown', keyDownListener, true);
            window.addEventListener('mousemove', mouseMoveListener, true);

            return () => {
                window.removeEventListener('click', clickListener, true);
                window.removeEventListener('keydown', keyDownListener, true);
                window.removeEventListener('mousemove', mouseMoveListener, true);
            };
        }
    }, [devMode]);

    useEffect(() => {
        if (process.env.REACT_APP_DEV_TOOLS) {
            toast.info(<>
                <h3>DevTools is ON</h3>
                <br />
                press [shift + alt + enter] to switch mode
            </>);
        }
    }, []);

    return (
        <div className={cn(
            'DevTools',
            devMode === DevModeEnum.TestId && 'is-testid-dev-mode',
            devMode === DevModeEnum.Interactive && 'is-interactive-dev-mode',
        )}>
            {children}
            {title !== '' && (
                <div key={devMode} className="DevTools__title">
                    {title}
                </div>
            )}
            {messages.length > 0 && (
                <div className="DevTools__message">
                    {messages.map((message, index) => (
                        <div key={index}>{message}</div>
                    ))}
                    {command !== '' && (
                        <div className="DevTools__command">{command}</div>
                    )}
                </div>
            )}
        </div>
    );
}

export default DevTools;