export function getApiLink(api: string, queryParams: any = {}) {
    const qs = getQueryString(queryParams);
    return `${api}${qs ? '?' : ''}${qs}`;
}

export function getQueryString(queryParams: any = {}, prefix = '') {
    return Object.keys(queryParams).reduce((acc, param) => {
        let paramString: string = '';

        const key = prefix ? `${prefix}[${param}]` : param;

        if (queryParams[param] !== null && queryParams[param] !== undefined) {
            if (Array.isArray(queryParams[param])) {
                paramString = queryParams[param].reduce((accc: string, val: string) => {
                    if (accc === '') {
                        return `${key}=${val}`
                    }
                    return `${accc}&${key}=${val}`;
                }, '');
            }
            else if (typeof queryParams[param] === 'object') {
                paramString = getQueryString(queryParams[param], key);
            } else {
                paramString = `${key}=${queryParams[param]}`;
            }
            if (paramString) {
                if (acc === '') {
                    return `${paramString}`
                }
                return `${acc}&${paramString}`;
            }
        }
        return acc;
    }, '')
}

export const getWithProtocol = (url: string, protocol: string) => {
    if (!url.trim()) return url;
    return new RegExp(`^${protocol}://`).test(url) ? url : `${protocol}://${url}`;
};

export function sanitize(url?: string): string {
    if (!url) return "";
    const urlWithoutDuplicateSlashes = url.replace(/([^:]\/)\/+/g, "$1");
    return getWithProtocol(urlWithoutDuplicateSlashes, 'https');
}