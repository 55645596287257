import React from 'react';
import { AiTile } from "../InspireTile/AiTile";
import { FCX, Id } from "@models";
import { iSelectedItemInfo } from "@pages/InspirePage/InspireResults/InspireResults";
import { INSPIRE_RESULTS_COUNT } from "@core/index";
import useInspireAiResultsStream from "@api/useInspireAiResultsStream";

const AiResults: FCX<{
    query: string;
    chainId: Id;
    segmentId: Id;
    isExpanded: boolean;
    onItemSelect: (item: iSelectedItemInfo) => void;
}> = ({
    query,
    chainId,
    segmentId,
    isExpanded,
    onItemSelect,
}) => {
    const { data } = useInspireAiResultsStream(query, chainId, segmentId);

    return (
        <>
            {data.slice(0, isExpanded ? INSPIRE_RESULTS_COUNT : 8).map((item, index) => (
                <AiTile
                    key={index}
                    name={item.name}
                    description={item.description}
                    isTall={isExpanded}
                    className="InspireResults__tile InspireResults__tile-ai"
                    onSelect={() => onItemSelect({
                        name: item.name,
                        description: item.description,
                        isAi: true,
                    })}
                    testId={`boxInspireResultsAiTile${index}`}
                />
            ))}
        </>
    );
}

export default AiResults;
