import React from 'react';
import cn from 'classnames';
import { FCX } from "@models";
import { IconStar } from "@svg/index";

const TileMetric: FCX<{
    title: string;
    value: number;
    stars: number;
}> = ({
    title,
    value,
    stars,
}) =>{
    return (
        <div className="LtoTile__metric TileMetric">
            <div className="TileMetric__title">
                {title}:
            </div>
            <div className="TileMetric__value">
                {value ? `${Math.round(value)}%` : '-'}
            </div>
            <div className={cn('TileMetric__stars', `stars-${stars}`)}>
                {stars > 0 && Array(stars).fill(null).map((_, index) => (
                    <IconStar key={index} className="TileMetric__star"/>
                ))}
            </div>
        </div>
    );
}

export default TileMetric;
