import { FCX } from "@core/models";
import cn from "classnames";

const Wrapper: FCX = ({
    className,
    style,
    id,
    children,
}) => {
    return (
        <div
            className={cn("Wrapper", className)}
            style={style}
            id={id}
        >
            {children}
        </div>
    );
}

export default Wrapper;