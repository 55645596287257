import React from 'react';
import './App.scss';
import Router from "@core/router";
import { BrowserRouter } from "react-router-dom";
import AxiosInterceptor from "./AxiosInterceptor";
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DevTools from "@components/DevTools";

function App() {

    return (
        <BrowserRouter>
            <AxiosInterceptor>
                <DevTools>
                    <div className="App" role="App">
                        <Router/>
                        <ToastContainer
                            position="top-right"
                            autoClose={3000}
                            hideProgressBar
                            closeOnClick
                            theme="colored"
                            transition={Slide}
                        />
                    </div>
                </DevTools>
            </AxiosInterceptor>
        </BrowserRouter>
    );
}

export default App;
