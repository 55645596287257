import { FC } from "react";
import Wrapper from "@components/Wrapper";

const LibraryPage: FC = () => {
    return (
        <div className="LibraryPage">
            <Wrapper>
                Library Page
            </Wrapper>
        </div>
    );
};

export default LibraryPage;