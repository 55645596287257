import { FCX, Id } from "@models";
import dayjs from "dayjs";
import LtoTile from "@pages/InspirePage/InspireTile/LtoTile";
import React, { useMemo } from "react";
import {
    iSelectedItemInfo,
    SORT_OPTION_DATE,
    SORT_OPTION_SCORE
} from "@pages/InspirePage/InspireResults/InspireResults";
import { iConceptItem } from "@models/ConceptItem";
import useInspireResults from "@api/useInspireResults";
import { TileLoader } from "@pages/InspirePage/InspireTile/TileLoader";
import { INSPIRE_RESULTS_COUNT } from "@core/index";

const Results: FCX<{
    defaultTilesCount: number;
    isResultsExpanded: boolean;
    respondentTypeId: Id;
    chainId: Id;
    segmentId: Id;
    query: string;
    sortBy: Id;
    onItemSelect: (item: iSelectedItemInfo) => void;
}> = ({
    defaultTilesCount,
    isResultsExpanded,
    respondentTypeId,
    chainId,
    segmentId,
    query,
    sortBy,
    onItemSelect,
}) => {
    const { isLoading, data: rawData } = useInspireResults({
        chainId,
        segmentId,
        respondentTypeId,
        query,
    });

    const data = useMemo(
        () => {
            const result = [...rawData];

            const getSortingValue = (item: iConceptItem) => {
                if (sortBy === SORT_OPTION_DATE.id) {
                    return dayjs(item.date).valueOf();
                } else if (sortBy === SORT_OPTION_SCORE.id && !!item.index) {
                    return item.index.score;
                }

                return 0;
            }

            result.sort((a, b) => {
                const aScore = getSortingValue(a);
                const bScore = getSortingValue(b);

                return bScore - aScore;
            });

            return result.slice(0, isResultsExpanded ? INSPIRE_RESULTS_COUNT : defaultTilesCount);
        },
        [rawData, isResultsExpanded, defaultTilesCount, sortBy]
    );

    const tilesCount = useMemo(
        () => {
            return isResultsExpanded ? data.length || INSPIRE_RESULTS_COUNT : defaultTilesCount;
        },
        [data, isResultsExpanded, defaultTilesCount],
    );

    return (
        <>
            {isLoading && Array(tilesCount).fill(null).map((_, index) => (
                <TileLoader key={index} className="InspireResults__tile"/>
            ))}
            {data.map((i, index) => (
                <LtoTile
                    isTall={isResultsExpanded}
                    className="InspireResults__tile"
                    data={i}
                    onSelect={() => onItemSelect({ name: i.name, description: i.description, })}
                    testId={`boxInspireResultsTile${index}`}
                />
            ))}
        </>
    );
};

export default Results;