import { FCX, Id, iOption } from "@models";
import cn from "classnames";
import React from "react";

interface iSelectSegmentChainOption extends iOption {
    children?: React.ReactNode;
    id: Id;
    isActive?: boolean;
}

const OptionsList: FCX<{
    data: iSelectSegmentChainOption[];
    onOptionSelect: (option: iSelectSegmentChainOption) => void;
    testId: string;
}> = ({
    data,
    onOptionSelect,
    testId,
}) => {
    return (
        <div className="SelectSegmentChain__list" data-testid={testId}>
            {data.map(option => (
                <div
                    key={option.id}
                    className={cn(
                        'SelectSegmentChain__option',
                        'Select__option',
                        'interactive',
                        option.isActive && 'is-active',
                    )}
                    onClick={() => onOptionSelect(option)}
                    data-testid="buttonSelectOption"
                >
                    {option.children || option.name}
                </div>
            ))}
        </div>
    );
};

export default OptionsList;