import React, { useMemo } from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import './LtoTile.scss';
import TileMetric from './TileMetric';
import { includesInLC } from "@core/utils/string";
import { IconExternalLink, IconStatusLimited, IconStatusNew, IconStatusNote, IconStatusReturn } from "@svg/index";
import { FCX, Icon } from "@models";
import { iConceptItem } from "@models/ConceptItem";
import { Tooltip } from "react-tooltip";
import Button from "@components/Button";
import useLaunchedItemPptReport from "@api/useLaunchedItemPptReport";

const getStatusIcon = (status: string) => {
    let Icon: Icon | undefined;
    if (includesInLC(status, 'limited')) {
        Icon = IconStatusLimited;
    } else if (includesInLC(status, 'new')) {
        Icon = IconStatusNew;
    } else if (includesInLC(status, 'return')) {
        Icon = IconStatusReturn;
    }

    if (Icon) {
        return (
            <Icon className="LtoTile__status-icon"/>
        );
    }

    return null;
}

const LtoTile: FCX<{
    data: iConceptItem;
    isTall?: boolean;
    onSelect: (data: { name: string; description: string }) => void;
}> = ({
    className,
    data,
    isTall,
    onSelect,
    testId
}) => {
    const genericId = useMemo(() => uuid(), []);
    const { isLoading, load } = useLaunchedItemPptReport();

    const {
        id,
        chainName,
        date,
        name,
        imageUrl,
        description,
        index,
        kind,
        note,
        url,
    } = data;

    return (
        <div
            className={cn(
                'LtoTile',
                isTall && 'is-tall',
                className,
            )}
        >
            <div
                className="LtoTile__inner"
                data-testid={testId}
            >
                <div className="LtoTile__header">
                    <div className="LtoTile__chain">
                        {chainName}
                    </div>
                    <div className="LtoTile__date">
                        {dayjs(date).format('MMM YYYY')}
                    </div>
                </div>
                <div className="LtoTile__title">
                    {name}
                </div>
                <div className="LtoTile__thumbnail">
                    <img
                        src={imageUrl.replace('w=200', 'w=300').replace('h=200', 'h=170')}
                        alt={name}
                        className="LtoTile__image"
                    />
                    {!!index && (
                        <div className="LtoTile__actions">
                            <a
                                href={url}
                                className="LtoTile__action interactive"
                                data-testid={`buttonLtoTileDetailsLink`}
                                target="_blank" rel="noreferrer"
                            >
                                VIEW ITEM DETAILS
                                <IconExternalLink className="LtoTile__action-icon"/>
                            </a>
                            <div
                                className={cn(
                                    'LtoTile__action interactive',
                                    isLoading && 'is-loading',
                                )}
                                data-testid={`buttonLtoTileDownloadPpt`}
                                onClick={() => load(id)}
                            >
                                {isLoading ? 'Downloading...' : 'DOWNLOAD REPORT'}
                                {isLoading && (
                                    <div className="InspireProgressLoader is-inside-button"/>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className={cn('LtoTile__description', !index && 'is-extended')}>
                    {description}
                    <Button
                        className="LtoTile__go-button"
                        onClick={() => onSelect({ name, description })}
                        testId={`buttonLtoTileSelect`}
                    >
                        Select
                    </Button>
                </div>
                <div className={cn('LtoTile__status', !index && 'is-visible')}>
                    {getStatusIcon(kind)}&nbsp;&nbsp;{kind}
                    {!!note && (
                        <div
                            className="LtoTile__status-note"
                            data-tooltip-id={`${genericId}-note`}
                        >
                            <IconStatusNote
                                className="LtoTile__status-icon"
                                style={{ transform: 'rotateX(180deg)' }}
                            />
                            <Tooltip
                                id={`${genericId}-note`}
                                place="top-end"
                                variant={'light'}
                            >
                                <div className="InspirePage__tooltip s">
                                    {note}
                                </div>
                            </Tooltip>
                        </div>
                    )}
                </div>
                {!!index && (() => {
                    const {
                        unbrandedPi,
                        unbrandedPiStar,
                        uniqueness,
                        uniquenessStar,
                        frequency,
                        frequencyStar,
                        draw,
                        drawStar,
                        score,
                        viability,
                    } = index;

                    return (
                        <div className="LtoTile__stats">
                            <div className="LtoTile__metrics">
                                <TileMetric
                                    title="Unbranded PI"
                                    value={unbrandedPi}
                                    stars={unbrandedPiStar}
                                />
                                <TileMetric
                                    title="Uniqueness"
                                    value={uniqueness}
                                    stars={uniquenessStar}
                                />
                                <TileMetric
                                    title="Frequency"
                                    value={frequency}
                                    stars={frequencyStar}
                                />
                                <TileMetric
                                    title="Draw"
                                    value={draw}
                                    stars={drawStar}
                                />
                            </div>
                            <div className="LtoTile__score">
                                <div className="LtoTile__score-title">
                                    SCORE
                                </div>
                                <div className="LtoTile__score-value">
                                    {score}
                                </div>
                                <div className="LtoTile__score-label">
                                    {viability}
                                </div>
                            </div>
                        </div>
                    );
                })()}
            </div>
        </div>
    );
}

export default LtoTile;