import { useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { getFullApiLink } from "@api/index";
import { INSPIRE_RESULTS_COUNT } from "@core/index";
import { Id } from "@models";

export interface AiItem {
    name: string;
    description: string;
}

export default function useInspireAiResultsStream(query: string, chainId: Id, segmentId: Id) {
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState('');
    const ref = useRef('');

    useEffect(
        () => {
            setResult('');
            ref.current = uuid();
            const localRef = ref.current;
            const abortController = new AbortController();
            setIsLoading(true);

            const request = fetch(
                getFullApiLink('/InspirePage/GetGeneratedItemsStream'),
                {
                    method: 'post',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        searchText: query,
                        chainId: +chainId || null,
                        segmentId: +segmentId || null,
                    }),
                    signal: abortController.signal,
                }
            );

            request.then(res => res.body?.getReader()).then((reader) => {
                const decoder = new TextDecoder('utf-8');
                let result = '';

                function continueTake() {
                    if (reader) {
                        reader.read().then(({ done, value }) => {
                            if (localRef === ref.current) {
                                const chunk = decoder.decode(value);
                                setResult(result += chunk);
                                if (!done) {
                                    continueTake();
                                }
                                else {
                                    setIsLoading(false);
                                }
                            }
                        });
                    }
                }

                continueTake();
            })
                .catch(() => {
                    setIsLoading(false);
                });

            return () => {
                abortController.abort();
            };
        },
        [query, segmentId, chainId],
    );

    const data = useMemo(
        (): AiItem[] => {
            const inlineResult = result
                .replaceAll('\n', '')
                .replaceAll('\r', '');

            const chunks = inlineResult.split('||');
            const items: any[] = [];

            Array(INSPIRE_RESULTS_COUNT).fill(null).forEach((_, index) => {
                const chunk = chunks[index] || '';
                let name = '';
                let description = '';

                if (chunk && chunk.indexOf('[[') >= 0) {
                    name = chunk
                        .replace(/<<.*(>>.*$)?/, '')
                        .replace(/^.*\[\[|]]/g, '');
                    description = chunk
                        .replace(/^.*\[\[.*]]|\[\[.*$/g, '')
                        .replace(/<<|>>.*$/g, '');
                }

                items.push({
                    name,
                    description,
                });
            });

            return items;
        },
        [result],
    );

    return {
        isLoading,
        data,
    };
}