import React, { useState, useRef } from 'react';
import cn from 'classnames';
import useOutsideClick from "@hooks/useOutsideClick";
import { FCX, Id, iOption } from "@models";
import './SelectSegmentChain.scss';
import { IconClose, IconSearch } from "@svg/index";
import { escapeRegExp } from "@utils/string";
import OptionsList from "@pages/InspirePage/SelectSegmentChain/OptionsList";

const SelectSegmentChain: FCX<{
    onSegmentSelect: (id: Id) => void;
    onChainSelect: (id: Id) => void;
    onReset: () => void;
    segments: iOption[];
    chains: iOption[];
    selectedChainId: Id;
    selectedSegmentId: Id;
    children: React.ReactNode;
    testId: string;
}
> = ({
    onSegmentSelect,
    onChainSelect,
    onReset,
    segments,
    chains,
    selectedChainId,
    selectedSegmentId,
    children,
    testId,
}) => {
    const [isOpened, setIsOpened] = useState(false);
    const [chainQuery, setChainQuery] = useState('');
    const [isPristine, setIsPristine] = useState(true);

    const refEl = useRef<HTMLDivElement | null>(null);
    useOutsideClick({
        ref: refEl,
        callback: () => setIsOpened(false),
    });

    const renderChainsAutocomplete = () => {
        const filteredChains = chains.filter((i) => {
            return new RegExp(escapeRegExp(chainQuery.trim()), 'gi').test(i.name.trim());
        });

        if (chainQuery.trim() === '' || isPristine) return null;

        const getScore = (name: string): number => {
            return new RegExp(`^${chainQuery.trim()}`, 'gi').test(name.trim()) ? 10 : 1;
        }

        filteredChains.sort((a, b) => getScore(b.name) - getScore(a.name));

        return (
            <OptionsList
                data={filteredChains.slice(0, 5).map(i => ({
                    id: i.id,
                    name: i.name,
                    children: (
                        <div dangerouslySetInnerHTML={{
                            __html: i.name.trim().replace(
                                new RegExp(`(${chainQuery.trim()})`, 'gi'),
                                '<b>$1</b>'
                            ),
                        }}/>
                    ),
                }))}
                testId={`${testId}SegmentsList`}
                onOptionSelect={(option) => {
                    onChainSelect(option.id);
                    setIsOpened(false);
                    setIsPristine(true);
                    setChainQuery(option.name);
                }}
            />
        );
    };

    return (
        <div
            className={cn(
                'SelectSegmentChain',
                'Select',
                isOpened && 'is-focused',
            )}
            ref={refEl}
            onClick={() => { setIsOpened(!isOpened); }}
        >
            <div
                className="Select__thumbnail interactive"
                data-testid={testId}
            >
                {children}
            </div>
            {isOpened && (
                <div
                    className={cn(
                        'SelectSegmentChain__dropdown',
                        'Select__dropdown',
                    )}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="SelectSegmentChain__column">
                        <OptionsList
                            data={[{
                                id: 0,
                                name: 'All segments',
                                isActive: selectedSegmentId === 0 && selectedChainId === 0,
                            }]}
                            testId={`${testId}OptionsList`}
                            onOptionSelect={(option) => {
                                onSegmentSelect(option.id);
                                setIsOpened(false);
                                setChainQuery('');
                            }}
                        />

                        <div className="SelectSegmentChain__title">
                            SEGMENT
                        </div>
                        <OptionsList
                            data={segments}
                            testId={`${testId}ChainsList`}
                            onOptionSelect={(option) => {
                                onSegmentSelect(option.id);
                                setIsOpened(false);
                                setChainQuery('');
                            }}
                        />
                    </div>
                    <div className="SelectSegmentChain__column">
                        <div
                            className="Select__option SelectSegmentChain__option"
                            style={{ opacity: 0, pointerEvents: 'none' }}
                        >
                            &nbsp;
                        </div>
                        <div className="SelectSegmentChain__title">CHAIN</div>
                        <div className="SelectSegmentChain__search">
                            <IconSearch className="SelectSegmentChain__search-icon"/>
                            <input
                                className="SelectSegmentChain__search-input"
                                value={chainQuery}
                                placeholder="chain name"
                                data-testid="inputSelectSegmentChainSearchChain"
                                onChange={(e) => {
                                    setChainQuery(e.target.value);
                                    setIsPristine(false);
                                }}
                            />
                            <div
                                data-testid="buttonSelectSegmentChainClearSearchChain"
                                className={cn(
                                    'SelectSegmentChain__search-clear',
                                    'interactive',
                                    chainQuery.length === 0 && 'is-hidden',
                                )}
                                onClick={() => {
                                    onReset();
                                    setChainQuery('');
                                }}
                            >
                                <IconClose className="SelectSegmentChain__search-clear"/>
                            </div>
                        </div>
                        {renderChainsAutocomplete()}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SelectSegmentChain;