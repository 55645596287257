import { useState, useRef, useEffect, useCallback } from 'react';
import axios, { AxiosRequestConfig, Method } from 'axios';
import { paths } from './apiSchema';
import { getApiLink, sanitize } from "@core/utils/url";

type Config<P = unknown> = Partial<AxiosRequestConfig<P>>;

type useAxiosArgs<P> = {
    method?: Method;
    url: keyof paths;
    payload?: P;
    lazy?: boolean;
    initialConfig?: Config<P>;
};

export type useAxiosReturn<R, P> = {
    cancel: () => void;
    data: R | null;
    error: string;
    isLoading: boolean;
    doFetch: (newConfig?: Config<P>) => Promise<void>;
    httpStatus: null | number;
};

const Api = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    withCredentials: true,
    headers: {
        Accept: 'application/json',
    },
});

export default Api;

export function getFullApiLink(api: keyof paths, queryParams: any = {}) {
    return sanitize(`${process.env.REACT_APP_API}/${getApiLink(api, queryParams)}`);
}

export const useFetch = <R = unknown, P = unknown>({
    initialConfig,
    method = 'GET',
    url,
    payload,
    lazy,
}: useAxiosArgs<P>): useAxiosReturn<R, P> => {
    const [data, setData] = useState<R | null>(null);
    const [httpStatus, setHttpStatus] = useState<number | null>(null);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(!lazy);

    const defaultConfig = useRef<Config<P> | undefined>(initialConfig);
    const abortControllerRef = useRef<AbortController | null>(null);

    const isLazy = lazy ?? false;

    const cancelRunningRequest = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    };

    const doFetch = useCallback(
        async (newConfig: Config<P> = {}) => {
            cancelRunningRequest();
            setHttpStatus(null);

            abortControllerRef.current = new AbortController();

            setIsLoading(true);

            const mergedConfig = {
                ...defaultConfig?.current,
                ...newConfig,
            };

            try {
                const response = await Api.request<R>({
                    data: payload,
                    signal: abortControllerRef.current.signal,
                    method,
                    url,
                    ...mergedConfig,
                });

                setHttpStatus(response.status);

                setData(response.data);
                setError('');
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    setError(error.message);
                    setHttpStatus(error.response?.status || null);
                }

                return Promise.reject(error);
            } finally {
                abortControllerRef.current = null;
                setIsLoading(false);
            }
        },
        [method, payload, url],
    );

    useEffect(() => {
        if (!isLazy) {
            const timer = setTimeout(() => {
                doFetch().catch(err => {
                    if (err.code !== "ERR_CANCELED") {
                        console.error(err);
                    }
                });
            }, 50);

            return () => {
                clearTimeout(timer);
            }
        }
    }, [doFetch, isLazy]);

    useEffect(() => {
        return () => {
            cancelRunningRequest();
        };
    }, []);

    return { doFetch, data, error, isLoading, cancel: cancelRunningRequest, httpStatus };
};

export type ApiFileExport<T> = { isLoading: boolean; load: (args: T) => void; };