import React, { useCallback, useMemo, useState } from 'react';
import cn from "classnames";
import { Typewriter } from "react-simple-typewriter";
import { FCX, Id, iOption } from "@models";
import SortSelect from "@pages/InspirePage/InspireResults/SortSelect";
import Results from "@pages/InspirePage/InspireResults/Results";
import useRandomItems from "@hooks/useRandomItems";
import SelectSegmentChain from "../SelectSegmentChain/SelectSegmentChain";
import { INPUT_PLACEHOLDERS, SELECT_PLACEHOLDER } from "../InspirePage";
import AiResults from "./AiResults";
import AppealSelect from "./AppealSelect";
import './InspireResults.scss';
import { Tooltip } from "react-tooltip";
import { IconArrowRight } from "@svg/index";
import Button from "@components/Button";
import useWindowSizeDebounced from "@hooks/useWindowSizeDebounced";

export const SORT_OPTION_BEST_MATCH = { id: '', name: 'Best Match' };
export const SORT_OPTION_DATE = { id: 'date', name: 'Date' };
export const SORT_OPTION_SCORE = { id: 'score', name: 'Score' };

export const SORT_OPTIONS = [
    SORT_OPTION_BEST_MATCH,
    SORT_OPTION_DATE,
    SORT_OPTION_SCORE,
];

export interface iSelectedItemInfo {
    name: string;
    description: string;
    isAi?: boolean;
}

const InspireResults: FCX<{
    query: string;
    onQueryChange: (value: string) => void;
    segmentChainSelectionText: string;
    onSegmentSelect: (id: Id) => void;
    onChainSelect: (id: Id) => void;
    onReset: () => void;
    segments: iOption[];
    chains: iOption[];
    selectedChainId: Id;
    selectedSegmentId: Id;
    generationRespondents: iOption[];
}> = ({
    query,
    onQueryChange,
    segmentChainSelectionText,
    onSegmentSelect,
    onChainSelect,
    onReset,
    segments,
    chains,
    selectedChainId,
    selectedSegmentId,
    generationRespondents
}) => {
    const { height} = useWindowSizeDebounced();
    const isNarrowScreen = useMemo(
        () => height < 800,
        [height]
    );

    const [appliedSegmentId, setAppliedSegmentId] = useState<Id>(selectedSegmentId);
    const [appliedChainId, setAppliedChainId] = useState<Id>(selectedChainId);
    const [appliedQuery, setAppliedQuery] = useState<string>(query);

    const [isResultsExpanded, setIsResultsExpanded] = useState(false);
    const [isAiResultsExpanded, setIsAiResultsExpanded] = useState(false);

    const [selectedRespondentTypeId, setSelectedRespondentTypeId] = useState<Id>(0);
    const [sortBy, setSortBy] = useState<Id>(SORT_OPTIONS[0].id);

    const defaultTilesCount = useMemo(
        () => isNarrowScreen ? 4 : 8,
        [isNarrowScreen]
    );

    const placeholders = useRandomItems(INPUT_PLACEHOLDERS);

    const isSubmitDenied = useMemo(
        () =>
            appliedChainId === selectedChainId
            && appliedSegmentId === selectedSegmentId
            && (query.trim() === appliedQuery.trim() || query.trim() === '')
        ,
        [selectedChainId, appliedChainId, selectedSegmentId, appliedSegmentId, query, appliedQuery]
    );

    const getResults = useCallback(
        () => {
            setIsAiResultsExpanded(false);
            setIsResultsExpanded(false);

            setAppliedSegmentId(selectedSegmentId);
            setAppliedChainId(selectedChainId);
            setAppliedQuery(query);
        },
        [selectedSegmentId, selectedChainId, query]
    );

    return (
        <div className={cn('InspireResults', 'is-headers-sticky')}>
            <div className="InspireResults__header InspireSection InspireSection--1">
                <div style={{ textAlign: 'right' }}>
                    Here are some ideas<br/>that could work for
                </div>
                <div className="InspireResults__d"/>
                <div className="InspireResults__d"/>
                <div className="InspireResults__input-container">
                    <label className="InspireResults__label">
                        ITEM TYPE&nbsp;
                    </label>
                    <input
                        className="InspireResults__input xl"
                        value={query}
                        onChange={e => onQueryChange(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !isSubmitDenied) {
                                getResults();
                            }
                        }}
                        data-testid="inputInspirePageResultsQuery"
                    />
                    <div
                        className="InspireResults__input is-placeholder"
                        style={{
                            opacity: query === '' ? undefined : 0,
                        }}
                    >
                        <Typewriter
                            loop={0}
                            typeSpeed={50}
                            deleteSpeed={10}
                            delaySpeed={3000}
                            words={placeholders}
                        />
                    </div>
                </div>
                <div className="InspireResults__d"/>
                <div className="InspireResults__d"/>
                <div>at</div>
                <div className="InspireResults__d"/>
                <div className="InspireResults__d"/>
                <div className="InspireResults__input-container">
                    <label className="InspireResults__label">
                        PLACE&nbsp;
                    </label>
                    <SelectSegmentChain
                        selectedChainId={selectedChainId}
                        selectedSegmentId={selectedSegmentId}
                        onSegmentSelect={onSegmentSelect}
                        onChainSelect={onChainSelect}
                        onReset={onReset}
                        chains={chains}
                        segments={segments}
                        testId="dropdownInspirePageResultsSelectSegmentChain"
                    >
                        <div
                            className={cn(
                                'InspireResults__input',
                                'interactive',
                                segmentChainSelectionText.toLowerCase() === SELECT_PLACEHOLDER && 'is-dimmed',
                            )}
                        >
                            <div className="InspireResults__input-inner">
                                {segmentChainSelectionText}
                            </div>
                        </div>
                    </SelectSegmentChain>
                </div>
                <div className="InspireResults__d"/>
                <Button
                    className={cn(
                        'InspireResults__button',
                        query.trim() === '' && 'is-empty',
                    )}
                    onClick={getResults}
                    disabled={isSubmitDenied}
                    data-tooltip-id={'inspire-results-button-tooltip'}
                    data-testid="buttonInspirePageResultsSubmit"
                >
                    <IconArrowRight color={isSubmitDenied ? undefined : '#fff'}/>
                    {isSubmitDenied && (
                        <Tooltip
                            id={'inspire-results-button-tooltip'}
                            place={'bottom'}
                            variant={'light'}
                            positionStrategy="fixed"
                        >
                            <div className="InspirePage__tooltip">
                                to continue please<br/> describe your idea briefly
                            </div>
                        </Tooltip>
                    )}
                </Button>
            </div>
            <div className="InspireResults__sub-header InspireSection InspireSection--2">
                <div className="InspireResults__sub-header-text">
                    CLICK ANY ITEM TO START OPTIMIZING
                </div>
            </div>
            <div
                className={cn(
                    'InspireResults__section',
                    isAiResultsExpanded && 'is-hidden',
                )}
            >
                <div className="InspireResults__section-header InspireSection InspireSection--3">
                    <div style={{ display: 'flex', }}>
                        <div style={{ fontWeight: 600 }}>Launched Items</div>
                        <div
                            className="InspireResults__toggle-button interactive"
                            onClick={() => {
                                setIsResultsExpanded(!isResultsExpanded);
                                document.getElementById('page-inspire')?.scrollTo({ top: 0 });
                            }}
                            data-testid="buttonInspirePageResultsToggleView"
                        >
                            {isResultsExpanded ? 'Show less' : 'Show all'} {'>'}
                        </div>
                    </div>
                    <div style={{ display: 'flex', }}>
                        <AppealSelect
                            value={selectedRespondentTypeId}
                            options={generationRespondents}
                            setValue={setSelectedRespondentTypeId}
                        />
                        &nbsp;&nbsp;
                        <SortSelect value={sortBy} setValue={setSortBy}/>
                    </div>
                </div>
                <div className="InspireResults__grid InspireSection InspireSection--4">
                    <Results
                        sortBy={sortBy}
                        respondentTypeId={selectedRespondentTypeId}
                        chainId={appliedChainId}
                        segmentId={appliedSegmentId}
                        isResultsExpanded={isResultsExpanded}
                        onItemSelect={() => console.log('go to optimize')}
                        defaultTilesCount={defaultTilesCount}
                        query={appliedQuery}
                    />
                </div>
            </div>
            <div
                className={cn(
                    'InspireResults__section',
                    isResultsExpanded && 'is-hidden',
                )}
            >
                <div className="InspireResults__section-header InspireSection InspireSection--5">
                    <div style={{ display: 'flex' }}>
                        <div style={{ fontWeight: 600 }}>AI Generated Items</div>
                        <div
                            className="InspireResults__toggle-button interactive"
                            onClick={() => {
                                setIsAiResultsExpanded(!isAiResultsExpanded);
                                document.getElementById('page-inspire')?.scrollTo({ top: 0 });
                            }}
                            data-testid="buttonInspirePageResultsToggleAiView"
                        >
                            {isAiResultsExpanded ? 'Show less' : 'Show all'} {'>'}
                        </div>
                    </div>
                </div>
                <div className="InspireResults__grid InspireSection InspireSection--6">
                    {appliedQuery !== '' && (
                        <AiResults
                            query={appliedQuery}
                            chainId={appliedChainId}
                            segmentId={appliedSegmentId}
                            isExpanded={isAiResultsExpanded}
                            onItemSelect={() => console.log('go to optimize')}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default InspireResults;