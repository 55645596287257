import cn from 'classnames';
import React from "react";
import { FCX } from "@models";

export const TileLoader: FCX = ({ className }) => {
    return (
        <div className={cn('LtoTile', className, 'is-loader')}>
            <div className="LtoTile__inner">
                <div className="LtoTile__header">
                    <div className="LtoTile__loader" style={{ width: '20%' }}/>
                    <div className="LtoTile__loader" style={{ width: '15%' }}/>
                </div>
                <div className="LtoTile__title">
                    <div className="LtoTile__loader"/>
                    &nbsp;
                </div>
                <div className="LtoTile__description">
                    <div className="LtoTile__loader" style={{ width: '90%', }}/>
                    <div className="LtoTile__loader" style={{ width: '60%', }}/>
                    <div className="LtoTile__loader" style={{ width: '75%', }}/>
                </div>
                <div className="LtoTile__stats">
                    <div className="LtoTile__loader a"/>
                </div>
            </div>
        </div>
    );
};
