import cn from "classnames";
import React from "react";
import { FCX } from "@models";
import Button from "@components/Button";

export const AiTile: FCX<{
    name: string;
    description: string;
    isTall: boolean;
    onSelect: () => void;
}> = ({
    className,
    name,
    description,
    isTall,
    onSelect,
    testId,
}) => {
    return (
        <div
            className={cn(
                'LtoTile',
                className,
                isTall && 'is-tall',
                name === '' && description === '' && 'is-loader',
            )}
        >
            <div
                className="LtoTile__inner"
                data-testid={testId}
            >
                <div className="LtoTile__header">
                    &nbsp;
                </div>
                <div className="LtoTile__title">
                    {name === '' && (
                        <React.Fragment>
                            <div className="LtoTile__loader"/>
                            &nbsp;
                        </React.Fragment>
                    )}
                    {name}
                </div>
                <div className={cn(
                    'LtoTile__description',
                    isTall && 'is-extended',
                )}>
                    {description === '' && (
                        <React.Fragment>
                            <div className="LtoTile__loader" style={{ width: '90%', }}/>
                            <div className="LtoTile__loader" style={{ width: '60%', }}/>
                            <div className="LtoTile__loader" style={{ width: '75%', }}/>
                            &nbsp;
                        </React.Fragment>
                    )}
                    {description}
                    <Button
                        className="LtoTile__go-button"
                        onClick={onSelect}
                        testId={`buttonAiTileSelect`}
                    >
                        Select
                    </Button>
                </div>
                <div className="LtoTile__badge">
                    AI generated item
                </div>
            </div>
        </div>
    );
}