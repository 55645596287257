import { FCX } from "@core/models";
import cn from "classnames";
import Wrapper from "@components/Wrapper";
import Routes from "@core/router/Routes";
import { NavLink } from "react-router-dom";
import { IconLogo } from "@svg/index";

const Header: FCX = ({
    className,
    style,
    id,
}) => {
    return (
        <div
            className={cn("Header", className)}
            style={style}
            id={id}
        >
            <Wrapper className="flex justify align">
                <NavLink
                    to={Routes.Build}
                    className="Header__logo interactive"
                    data-testid="buttonHeaderNavigationLogo"
                >
                    <IconLogo
                        className="Header__logo-icon"
                    />
                </NavLink>
                <div className="Header__navigation">
                    <NavLink
                        className="Header__navigation-item interactive"
                        data-testid="buttonHeaderNavigationBuildPage"
                        to={Routes.Build}
                    >
                        Build
                    </NavLink>
                    <NavLink
                        className="Header__navigation-item interactive"
                        data-testid="buttonHeaderNavigationInspirePage"
                        to={Routes.Inspire}
                    >
                        Inspire
                    </NavLink>
                    <NavLink
                        className="Header__navigation-item interactive"
                        data-testid="buttonHeaderNavigationLibraryPage"
                        to={Routes.Library}
                    >
                        Library
                    </NavLink>
                </div>
            </Wrapper>
        </div>
    );
}

export default Header;