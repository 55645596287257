import React from 'react';
import { Tooltip } from "react-tooltip";
import cn from 'classnames';
import { Typewriter } from 'react-simple-typewriter';
import { IconInfo } from "@svg/index";
import { Id } from "@models";
import { iCommonData } from "@api/useInspireCommonData";
import SelectSegmentChain from '../SelectSegmentChain/SelectSegmentChain';
import { INPUT_PLACEHOLDERS, SELECT_PLACEHOLDER } from '../InspirePage';
import './InspireIntro.scss';
import useRandomItems from "@hooks/useRandomItems";
import Button from "@components/Button";


const InspireIntro: React.FC<{
    query: string;
    onQueryChange: (value: string) => void;
    segmentChainSelectionText: string;
    onSegmentSelect: (id: Id) => void;
    onChainSelect: (id: Id) => void;
    onReset: () => void;
    segments: iCommonData['segments'];
    chains: iCommonData['chains'];
    selectedChainId: Id;
    selectedSegmentId: Id;
    goToResults: () => void;
}
> = ({
    query,
    onQueryChange,
    segmentChainSelectionText,
    onSegmentSelect,
    onChainSelect,
    onReset,
    segments,
    chains,
    selectedChainId,
    selectedSegmentId,
    goToResults,
}) => {
    const placeholders = useRandomItems(INPUT_PLACEHOLDERS);

    return (
        <div className="InspirePage__intro InspireIntro">
            <div>
                <div className="InspireIntro__title InspireSection InspireSection--1">
                    Need some inspiration? No problem.
                </div>
                <div className="InspireIntro__row InspireSection InspireSection--2">
                    <div className="InspireIntro__label">
                        Let's make a
                    </div>
                    <div style={{ position: 'relative' }}>
                        <input
                            autoFocus
                            className="InspireIntro__input xl"
                            value={query}
                            onChange={e => onQueryChange(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && query.trim() !== '') {
                                    goToResults();
                                }
                            }}
                            data-testid="inputInspirePageIntroQuery"
                        />
                        <div
                            className="InspireIntro__input is-placeholder"
                            style={{
                                opacity: query === '' ? undefined : 0,
                            }}
                        >
                            <Typewriter
                                loop={0}
                                typeSpeed={50}
                                deleteSpeed={10}
                                delaySpeed={3000}
                                words={placeholders}
                            />
                        </div>
                        <div className="InspirePage__info-icon InspireIntro__info-icon">
                            <IconInfo data-tooltip-id="inspire-intro-input-tooltip"/>
                            <Tooltip
                                id="inspire-intro-input-tooltip"
                                place="bottom-start"
                                variant={'light'}
                            >
                                <div className="InspirePage__tooltip m">
                                    Have a general idea but not sure on the specifics?
                                    <br/>
                                    <br/>
                                    Type it in here and we'll provide you some ideas to use as a starting point
                                    <br/>
                                    <br/>
                                    Let's create something amazing!
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="InspireIntro__row InspireSection InspireSection--3">
                    <div className="InspireIntro__label">
                        for
                    </div>
                    <SelectSegmentChain
                        selectedChainId={selectedChainId}
                        selectedSegmentId={selectedSegmentId}
                        onSegmentSelect={onSegmentSelect}
                        onChainSelect={onChainSelect}
                        onReset={onReset}
                        chains={chains}
                        segments={segments}
                        testId="dropdownInspirePageIntroSelectSegmentChain"
                    >
                        <div
                            className={cn(
                                'InspireIntro__input', 'm',
                                'interactive',
                                segmentChainSelectionText.toLowerCase() === SELECT_PLACEHOLDER && 'is-dimmed',
                            )}
                        >
                            <div className="InspireIntro__input-inner">
                                {segmentChainSelectionText}
                            </div>
                        </div>
                    </SelectSegmentChain>
                </div>
                <br/>
                <div className="InspireIntro__row InspireSection InspireSection--4">
                    <Button
                        className="InspireIntro__button"
                        disabled={query.trim() === ''}
                        onClick={goToResults}
                        data-tooltip-id="inspire-intro-button-tooltip"
                        data-testid="buttonInspirePageIntroGoToResults"
                    >
                        Show Me Ideas
                        {query.trim() === '' && (
                            <Tooltip
                                id="inspire-intro-button-tooltip"
                                className="InspirePage__tooltip"
                                variant={'light'}
                                place={'bottom'}
                            >
                                to continue please<br/> describe your idea briefly
                            </Tooltip>
                        )}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default InspireIntro;