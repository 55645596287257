import { Id } from "@models";
import { useEffect, useMemo, useState } from "react";
import { useFetch } from "@api/index";
import ConceptItem, { iConceptItem } from "@models/ConceptItem";
import { components, paths } from "@api/apiSchema";

const url: keyof paths = '/InspirePage/GetLaunchedItems';
// type Request = paths['/InspirePage/GetLaunchedItems']['post']['requestBody']['content']['application/json'];
type Request = components["schemas"]["ConceptIdeation.InspirePage.Models.LaunchedItemsRequest"];
type Response = paths['/InspirePage/GetLaunchedItems']['post']['responses']['200']['content']['application/json'];

export default function useInspireResults({
    chainId,
    segmentId,
    respondentTypeId,
    query,
}: {
    chainId: Id;
    segmentId: Id;
    respondentTypeId: Id;
    query: string;
}) {
    const [data, setData] = useState<iConceptItem[]>([]);

    const payload = useMemo(
        (): Request => ({
            chainId: +chainId || null,
            segmentId: +segmentId || null,
            searchText: query,
            respondentType: +respondentTypeId || 1,
        }),
        [chainId, segmentId, query, respondentTypeId],
    );

    const result = useFetch<Response, Request>({
        url,
        method: 'post',
        payload,
    });

    useEffect(() => {
        if (!result.isLoading && !!result.data) {
            setData(result.data.map(i => new ConceptItem({ apiModel: i })));
        } else {
            setData([]);
        }
    }, [result.isLoading, result.data]);

    return {
        isLoading: result.isLoading,
        data,
    };
}