export function includesInLC(string1: string, string2: string) {
    return string1.toLowerCase().includes(string2.toLowerCase());
}

export function startsFromInLC(string1: string, string2: string) {
    return string1.toLowerCase().indexOf(string2.toLowerCase()) === 0;
}

export function equalInLC(string1?: string, string2?: string) {
    if (string1 === string2) return true;
    if (!string1 || !string2) return false;
    return string1.toLowerCase() === string2.toLowerCase();
}

export function renderMetricValue(value: number) {
    if (!Math.round(value)) return '-';
    return `${Math.round(value)}%`;
}

export function markMatch(str: string, query: string) {
    return str.replace(new RegExp(`(${query})`, 'i'), '<b>$1</b>');
}

export function prepareForMatching(str: string) {
    return str.trim().replace(/\s+/gm, ' ').toLowerCase();
}

export function getEnding(title: string, count: number) {
    const clearedTitle = prepareForMatching(title);
    switch(clearedTitle) {
        default: {
            return `${title}${count === 1 ? '' : 's'}`;
        }
    }
}

export function strToId(str: string, separator = '-'): string {
    return str.replace(/\s+/gi, separator);
}

export function getSubStrings(str: string, separators: string[] = [' ']): string[] {
    let parts = [str];
    separators.forEach(separator => {
        parts = parts.flatMap(part => part.split(separator));
    });

    return parts.map(i => i.trim()).filter(Boolean);
}

export function escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}