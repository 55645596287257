import { Icon } from "@models";
export const IconLogo: Icon = require('./logo.svg').ReactComponent;
export const IconInfo: Icon = require('./info.svg').ReactComponent;
export const IconStar: Icon = require('./star.svg').ReactComponent;

export const IconStatusLimited: Icon = require('./FaCertificate.svg').ReactComponent
export const IconStatusNew: Icon = require('./FaClock.svg').ReactComponent
export const IconStatusReturn: Icon = require('./FaStickyNote.svg').ReactComponent
export const IconStatusNote: Icon = require('./FaSyncAlt.svg').ReactComponent
export const IconExternalLink: Icon = require('./external.svg').ReactComponent
export const IconArrowRight: Icon = require('./arrow-right.svg').ReactComponent
export const IconSearch: Icon = require('./search.svg').ReactComponent
export const IconClose: Icon = require('./close.svg').ReactComponent