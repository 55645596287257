import { Id } from "@models";
import { useState } from "react";
import Api, { ApiFileExport } from "@api/index";
import { downloadReport } from "@core/utils/file";
import { paths } from "@api/apiSchema";
import { getApiLink } from "@core/utils/url";

const url: keyof paths = '/Export/GetScoresPpt';

export default function useLaunchedItemPptReport(): ApiFileExport<Id> {
        const [isLoading, setIsLoading] = useState(false);

        const load = async (itemId: Id) => {
            setIsLoading(true);

            await downloadReport(Api.request({
                method: 'get',
                url: getApiLink(url, { itemId }),
                responseType: 'blob',
            }));

            setIsLoading(false);
        };

        return {
            isLoading,
            load,
        }
}