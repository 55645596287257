import { FCX } from "@models";
import cn from "classnames";
import { Outlet } from "react-router-dom";
import Header from "@vms/Header";

const Layout: FCX = ({
    className,
    style,
    id,
}) => {
    return (
        <div
            className={cn("Layout", className)}
            style={style}
            id={id}
        >
            <Header/>
            <Outlet/>
        </div>
    );
};

export default Layout;