import { useMemo } from "react";
import { FCX, Id } from "@models";
import cn from "classnames";
import { SORT_OPTIONS } from "@pages/InspirePage/InspireResults/InspireResults";
import Select from "@components/Select";

const SortSelect: FCX<{
    value: Id;
    setValue: (id: Id) => void;
}> = ({
    value,
    setValue,
}) => {
    const text = useMemo(
        () => SORT_OPTIONS.find(i => i.id === value)?.name,
        [value]
    );

    return (
        <div className="InspireResults__input-container">
            <Select
                options={SORT_OPTIONS}
                value={[value]}
                onChange={setValue}
                isDarkMode
                closeOnSelect
                testId="dropdownInspirePageResultsSelectSortBy"
            >
                <div
                    className={cn(
                        'InspireResults__input',
                        'is-dark-mode',
                    )}
                >
                    <div className="InspireResults__input-inner">
                        Sort By: {text}
                    </div>
                </div>
            </Select>
        </div>
    );
};

export default SortSelect;