import { FC, useCallback, useEffect, useMemo, useState } from "react";
import Wrapper from "@components/Wrapper";
import useInspireCommonData from "@api/useInspireCommonData";
import InspireIntro from "@pages/InspirePage/InspireIntro/InspireIntro";
import InspireResults from "@pages/InspirePage/InspireResults/InspireResults";
import { Id } from "@models";

export const SELECT_PLACEHOLDER = 'all restaurants';

export const INPUT_PLACEHOLDERS = [
    'burger with mushrooms',
    'healthy appetizer',
    'vegetarian pizza',
    'wrap with mediterranean flavors',
    'indulgent dessert',
    'bourbon based cocktail',
    'refreshing summer drink',
    'zesty fried starter',
    'spicy noodle dish',
    'southwest style salad',
    'breakfast sandwich with guacamole',
    'non-alcoholic cocktail',
    'fruit based dessert',
];

const InspirePage: FC = () => {
    const [isIntro, setIsIntro] = useState(true);
    const [query, setQuery] = useState('');
    const [selectedChainId, setSelectedChainId] = useState<Id>(0);
    const [selectedSegmentId, setSelectedSegmentId] = useState<Id>(0);

    const {
        data: {
            generationRespondentTypes,
            segments,
            chains,
        },
    } = useInspireCommonData();

    const resetSegmentChain = useCallback(
        () => {
            setSelectedChainId(0);
            setSelectedSegmentId(0);
        },
        [],
    );

    useEffect(
        () => {
            setSelectedSegmentId(0);
        },
        [selectedChainId],
    );

    const segmentChainThumbnailText = useMemo(
        () => {
            if (selectedChainId !== 0) {
                const chain = chains.find(i => i.id === selectedChainId);
                return chain?.name || '';
            } else if (selectedSegmentId !== 0) {
                const segment = segments.find(i => i.id === selectedSegmentId);
                return segment?.name || '';
            }
            return SELECT_PLACEHOLDER;
        },
        [chains, segments, selectedChainId, selectedSegmentId],
    );

    return (
        <div className="InspirePage">
            <Wrapper>
                {isIntro && (
                    <InspireIntro
                        query={query}
                        onQueryChange={setQuery}
                        onReset={resetSegmentChain}
                        onSegmentSelect={setSelectedSegmentId}
                        onChainSelect={setSelectedChainId}
                        segments={segments}
                        chains={chains}
                        selectedSegmentId={selectedSegmentId}
                        selectedChainId={selectedChainId}
                        segmentChainSelectionText={segmentChainThumbnailText}
                        goToResults={() => setIsIntro(false)}
                    />)}

                {!isIntro && (
                    <InspireResults
                        query={query}
                        onQueryChange={setQuery}
                        segmentChainSelectionText={segmentChainThumbnailText}
                        onReset={resetSegmentChain}
                        segments={segments}
                        selectedSegmentId={selectedSegmentId}
                        onSegmentSelect={setSelectedSegmentId}
                        chains={chains}
                        selectedChainId={selectedChainId}
                        onChainSelect={setSelectedChainId}
                        generationRespondents={generationRespondentTypes}
                    />
                )}
            </Wrapper>
        </div>
    );
};

export default InspirePage;