import { RefObject, useEffect } from "react";
interface iOutsideClick {
    ref?: RefObject<HTMLElement>;
    isDisabled?: boolean;
    callback: () => void;
    ignoreNodesIds?: string[];
}
const useOutsideClick = ({ ref, callback, isDisabled = false, ignoreNodesIds = [] }: iOutsideClick) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                ref &&
                ref.current &&
                !ref.current.contains(event.target as Node) &&
                !ignoreNodesIds.includes((event.target as HTMLElement).id)
            ) {
                callback();
            }
        };
        if (!isDisabled) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [callback, ref, isDisabled, ignoreNodesIds]);
};

export default useOutsideClick;
