import React, { useState, useRef } from 'react';
import cn from 'classnames';
import { FCX, Id, iOption } from "@models";
import useOutsideClick from "@hooks/useOutsideClick";
import { MdCheck as IconCheck } from 'react-icons/md';

const Select: FCX<{
    options: iOption[];
    value: Id[];
    onChange: (id: Id) => void;
    isDarkMode: boolean;
    closeOnSelect: boolean;
}
    > = ({
    children,
    options,
    onChange,
    value,
    isDarkMode,
    className,
    closeOnSelect,
    testId,
}) => {
    const [isOpened, setIsOpened] = useState(false);
    const refEl = useRef<HTMLDivElement | null>(null);
    useOutsideClick({
        ref: refEl,
        callback: () => setIsOpened(false),
    });

    return (
        <div
            className={cn(
                'Select',
                isDarkMode && 'is-dark-mode',
                className,
                isOpened && 'is-focused',
            )}
            ref={refEl}
            onClick={() => { setIsOpened(!isOpened); }}
        >
            <div
                className="Select__thumbnail interactive"
                data-testid={testId}
            >
                {children}
            </div>
            {isOpened && (
                <div
                    className="Select__dropdown"
                    onClick={(e) => e.stopPropagation()}
                    data-testid={`${testId}Options`}
                >
                    {options.map((o) => (
                        <div
                            key={o.id}
                            className={cn(
                                'Select__option',
                                'interactive',
                                value.includes(o.id) && 'is-active',
                            )}
                            onClick={() => {
                                onChange(o.id);
                                if (closeOnSelect) setIsOpened(false);
                            }}
                            data-testid="buttonSelectOption"
                        >
                            <IconCheck className="Select__option-icon"/>
                            {o.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Select;